<template>
  <div :class="$style.tabWrap">
    <div :class="$style.tabHeader">
      <ul :class="$style.tabHeaderTitle">
        <li
          :class="[$style.title, carouselCurrent === 0 ? $style.active : '']"
          @click="handleGoToTab(0)"
        >
          {{ $t('iotScreenManage.sharingStatistics') }}
        </li>
        <li
          :class="[$style.title, carouselCurrent === 1 ? $style.active : '']"
          @click="handleGoToTab(1)"
        >
          {{ $t('iotScreenManage.sharingDetails') }}
        </li>
      </ul>
      <div v-if="carouselCurrent === 0" :class="$style.buttonBox">
        <div :class="$style.buttonList">
          <a-button
            v-for="(item, key) in buttonList"
            :key="key"
            :type="range === item.value ? 'primary' : ''"
            @click="rangeChange(item.value)"
            :class="$style.inButton"
          >
            {{ item.title }}
          </a-button>
        </div>
      </div>
      <div
        v-if="carouselCurrent === 1"
        :class="$style.formBox"
        class="shareFormBox"
      >
        <a-form layout="inline">
          <a-form-item :label="$t('iotScreenManage.sharingType')">
            <a-select
              default-value=""
              style="width: 120px"
              @change="handleShareTypeChange"
              :value="screenManageType"
              :getPopupContainer="triggerNode => triggerNode.parentNode"
            >
              <a-select-option value="">
                {{ $t('iotScreenManage.all') }}
              </a-select-option>
              <a-select-option value="EPC">
                {{ $t('iotScreenManage.contractor') }}
              </a-select-option>
              <a-select-option value="CONTRACTORINFO">
                {{ $t('iotScreenManage.tenant') }}
              </a-select-option>
              <a-select-option value="SECURITY_BUREAU">
                {{ $t('iotScreenManage.area') }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="$t('iotScreenManage.status')">
            <a-select
              default-value=""
              style="width: 120px"
              :value="isEffective"
              @change="handleStatusChange"
              :getPopupContainer="triggerNode => triggerNode.parentNode"
            >
              <a-select-option value="">
                {{ $t('iotScreenManage.all') }}
              </a-select-option>
              <a-select-option value="false">
                {{ $t('iotScreenManage.expired') }}
              </a-select-option>
              <a-select-option value="true">
                {{ $t('iotScreenManage.valid') }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <carousel ref="carouselRef" :onChange="handleTabChange">
      <div slot="first" :class="$style.carouselItem">
        <div :class="$style.chartBox">
          <div :class="$style.card">
            <share-survey id="shareChart" :data="shareSurveyData" />
          </div>
          <div :class="$style.card">
            <visit-survey
              id="visitChart"
              :data="visitSurveyData"
              @change="handleVisitChange"
            />
          </div>
        </div>
        <div :class="$style.countCard">
          <top
            id="shareCountChart"
            :data="top10Data"
            @change="handleTopChange"
          />
        </div>
      </div>
      <div slot="second" :class="$style.carouselItem">
        <share-detail-table ref="tableRef" />
      </div>
    </carousel>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import Carousel from './carousel';
import ShareSurvey from './chart/share-survey';
import VisitSurvey from './chart/visit-survey';
import Top from './chart/top';
import ShareDetailTable from './share-detail-table';
import {
  getTop10ForShare,
  getSharingStatistics,
} from '@/services/things/screen-count';
import { shareScreenModule } from '@/enum/store';

@Component({
  components: {
    Carousel,
    ShareSurvey,
    VisitSurvey,
    Top,
    ShareDetailTable,
  },
})
export default class ShareCount extends Vue {
  @shareScreenModule.State('params') params;
  @shareScreenModule.State('pagination') pagination;
  @shareScreenModule.Mutation('SET_PAGINATION') setPagination;
  @shareScreenModule.Mutation('SET_PARAMS') setParams;
  @shareScreenModule.Action('getShareList') getShareList;

  get buttonList() {
    return [
      {
        title: this.$t('matterwebset.screenmanage.last7'),
        value: 'BEFORE_SEVEN_DATE',
      },
      {
        title: this.$t('matterwebset.screenmanage.last30'),
        value: 'BEFORE_THIRTY_DATE',
      },
      {
        title: this.$t('matterwebset.screenmanage.currentmonth'),
        value: 'CURRENT_MONTH',
      },
    ];
  }
  range = 'BEFORE_SEVEN_DATE';
  async rangeChange(value) {
    this.range = value;
    this.defaultOptions.screenCountDate = value;
    await this.getQuery();
  }

  screenManageType = '';
  handleShareTypeChange(value) {
    this.screenManageType = value;
    this.changeTableForm();
  }
  isEffective = '';
  handleStatusChange(value) {
    this.isEffective = value;
    this.changeTableForm();
  }
  async changeTableForm() {
    this.setPagination({
      ...this.pagination,
      current: 1,
    });
    this.setParams({
      ...this.params,
      isEffective: this.isEffective,
      screenManageType: this.screenManageType,
    });
    await this.getShareList();
  }

  carouselCurrent = 0;
  handleTabChange(current) {
    this.carouselCurrent = current;
  }
  handleGoToTab(index) {
    this.carouselCurrent = index;
    this.$refs.carouselRef.handleGoTo(index);
  }

  async mounted() {
    await this.getQuery();
    await this.getShareList();
  }

  defaultOptions = {
    screenCountDate: 'BEFORE_SEVEN_DATE',
  };
  async getQuery() {
    const options = {
      ...this.defaultOptions,
    };
    await this.getShareSurveyData(options);
    await this.getVisitSurveyData(options);
    await this.getTop10ShareForCountData(options);
  }
  top10Data = {};
  topScreenCountAccess = 'NUMBER';
  async getTop10ShareForCountData(options) {
    const data = {
      screenCountAccess: this.topScreenCountAccess,
      ...options,
      screenCountType: 'ACCESS',
    };
    try {
      const result = await getTop10ForShare(data);
      const shareResult = await getTop10ForShare({
        ...data,
        screenCountAccess: 'COUNT',
        screenCountType: 'SHARE',
      });
      this.top10Data = this.formatTop10Data(
        result,
        shareResult,
        data.screenCountAccess,
      );
    } catch (error) {
      this.$message.error(error.message);
    }
  }
  formatTop10Data(result, shareResult, type) {
    const KeyType = {
      NUMBER: this.$t('iotScreenManage.visits'),
      COUNT: this.$t('iotScreenManage.clicks'),
    };
    const defValue = (value, key, format = '') => {
      if (value) {
        return value[key];
      } else {
        return format;
      }
    };
    const length = Math.max(
      result['EPC'].length,
      result['CONTRACTORINFO'].length,
      result['SECURITY_BUREAU'].length,
    );
    const listArea = [],
      listEpc = [],
      listLeaseholder = [],
      xAxis = [];
    for (let i = 0; i < length; i++) {
      xAxis.push(`第${i + 1}名`);
      listEpc.push({
        value: defValue(result['EPC'][i], 'number', 0),
        shareValue: defValue(shareResult['EPC'][i], 'number', 0),
        text: defValue(result['EPC'][i], 'screenTypeName'),
        type: KeyType[type],
      });
      listLeaseholder.push({
        value: defValue(result['CONTRACTORINFO'][i], 'number', 0),
        shareValue: defValue(shareResult['CONTRACTORINFO'][i], 'number', 0),
        text: defValue(result['CONTRACTORINFO'][i], 'screenTypeName'),
        type: KeyType[type],
      });
      listArea.push({
        value: defValue(result['SECURITY_BUREAU'][i], 'number', 0),
        shareValue: defValue(shareResult['SECURITY_BUREAU'][i], 'number', 0),
        text: defValue(result['SECURITY_BUREAU'][i], 'screenTypeName'),
        type: KeyType[type],
      });
    }
    return {
      listEpc,
      listLeaseholder,
      listArea,
      xAxis,
    };
  }
  async handleTopChange(value) {
    this.topScreenCountAccess = value;
    await this.getTop10ShareForCountData({
      ...this.defaultOptions,
    });
  }

  visitSurveyData = {};
  visitScreenCountAccess = 'NUMBER';
  async getVisitSurveyData(options) {
    const data = {
      screenCountAccess: this.visitScreenCountAccess,
      ...options,
      screenCountType: 'ACCESS',
    };
    try {
      const result = await getSharingStatistics(data);
      this.visitSurveyData = this.formatShareData(result);
    } catch (error) {
      this.$message.error(error.message);
    }
  }
  async handleVisitChange(value) {
    this.visitScreenCountAccess = value;
    await this.getVisitSurveyData({
      ...this.defaultOptions,
    });
  }
  formatShareData(result) {
    const times = [],
      listArea = [],
      listEpc = [],
      listLeaseholder = [];
    Object.keys(result).forEach(value => {
      times.push(value);
      listArea.push(
        result[value]['SECURITY_BUREAU'] ? result[value]['SECURITY_BUREAU'] : 0,
      );
      listEpc.push(result[value]['EPC'] ? result[value]['EPC'] : 0);
      listLeaseholder.push(
        result[value]['CONTRACTORINFO'] ? result[value]['CONTRACTORINFO'] : 0,
      );
    });
    return {
      times,
      listArea,
      listEpc,
      listLeaseholder,
    };
  }
  shareSurveyData = {};
  async getShareSurveyData(options) {
    const data = {
      ...options,
      screenCountType: 'SHARE',
    };
    try {
      const result = await getSharingStatistics(data);
      this.shareSurveyData = this.formatShareData(result);
    } catch (error) {
      this.$message.error(error.message);
    }
  }
}
</script>
<style lang="less" module>
.tabWrap {
  width: 100%;
  .tabHeader {
    height: 64px;
    line-height: 64px;
    display: flex;
    justify-content: space-between;

    .tabHeaderTitle {
      margin: 0;
      padding: 0;
      list-style: none;
      .title {
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        color: var(--font-info);
        &.active {
          color: var(--font);
          font-weight: bold;
        }
      }
      .title + .title {
        margin-left: 20px;
      }
    }

    .buttonBox {
      overflow: hidden;
    }
    .buttonList {
      float: right;
      .inButton {
        margin-left: 10px;
        height: 32px;
        line-height: 32px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .formBox {
      display: flex;
      align-items: center;
    }
  }

  .carouselItem {
    padding: 5px;
  }

  .chartBox {
    display: flex;
  }
  .card + .card {
    margin-left: 20px;
  }
  .card,
  .countCard {
    height: 361px;
    box-shadow: 0px 0px 10px 0px var(--shadow);
    border-radius: 10px;
    flex: 1;
    padding: 0 20px;
  }
  .countCard {
    margin-top: 20px;
  }
}

:global {
  .shareFormBox .ant-form-inline .ant-form-item {
    margin-right: 19px;
  }
  .shareFormBox .ant-form-inline .ant-form-item:last-of-type {
    margin-right: 0;
  }
  .shareFormBox .ant-form-inline .ant-form-item-label {
    margin-right: 10px;
  }
}
</style>
