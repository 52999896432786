<template>
  <div :class="$style.container">
    <div :class="[$style.title, $style.common]">
      <a-tooltip
        v-if="value == 'NUMBER'"
        :title="$t('iotScreenManage.visitingDesc')"
      >
        {{ $t('iotScreenManage.topVisited') }}&nbsp;&nbsp;<a-icon
          type="exclamation-circle"
        />
      </a-tooltip>
      <span v-else>{{ $t('iotScreenManage.topClicked') }}</span>
    </div>
    <div :class="[$style.selectBox, $style.common]">
      <a-select
        default-value="NUMBER"
        style="width: 100px"
        @change="handleChange"
      >
        <a-select-option value="NUMBER">
          {{ $t('iotScreenManage.visits') }}
        </a-select-option>
        <a-select-option value="COUNT">
          {{ $t('iotScreenManage.clicks') }}
        </a-select-option>
      </a-select>
    </div>
    <no-data-chart
      font="120px"
      v-if="isNoData"
      isIcon
      :style="{ height: '320px', width: '100%' }"
    />
    <div v-else :id="id" :class="$style.chartBox" :style="{ height }" />
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as echarts from 'echarts';
import resize from '@/utils/resize.js';
import { globalVueI18n } from '@triascloud/i18n';

const barOption = data => {
  const bodyCss = getComputedStyle(document.body);
  return {
    legend: {
      top: '15',
      right: '120',
      bottom: 200,
      textStyle: {
        fontSize: '14',
        color: '#9A9A9A',
      },
      data: [
        globalVueI18n.translate('screen.legend.generalContracting'),
        globalVueI18n.translate('screen.legend.tenant'),
        globalVueI18n.translate('screen.legend.area'),
      ],
    },
    grid: {
      left: '0',
      top: 77,
      right: '0',
      bottom: '3%',
      containLabel: true,
    },
    tooltip: {
      formatter: function(params) {
        var result = `<div>${params.data.type}: ${params.data.value}</div>`;
        result += `<div>分享次数: ${params.data.shareValue}</div>`;
        result += `<div>${params.seriesName}: ${params.data.text}</div>`;
        return result;
      },
    },
    xAxis: {
      type: 'category',
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        color: '#9A9A9A',
      },
      axisLine: {
        lineStyle: {
          color: bodyCss.getPropertyValue('--border'),
        },
      },
      data: data.xAxis,
    },
    yAxis: {
      axisLine: {
        show: false,
      },
      axisLabel: {
        color: '#9A9A9A',
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: bodyCss.getPropertyValue('--border'),
        },
      },
    },
    series: [
      {
        name: globalVueI18n.translate('screen.legend.generalContracting'),
        type: 'bar',
        barGap: 0,
        barWidth: '20',
        label: {
          show: false,
        },
        data: data.listEpc,
      },
      {
        name: globalVueI18n.translate('screen.legend.tenant'),
        type: 'bar',
        barWidth: '20',
        label: {
          show: false,
        },
        data: data.listLeaseholder,
      },
      {
        name: globalVueI18n.translate('screen.legend.area'),
        type: 'bar',
        barWidth: '20',
        label: {
          show: false,
        },
        data: data.listArea,
      },
    ],
    color: [
      'rgba(6, 173, 135, 1)',
      'rgba(58, 85, 234, 1)',
      'rgba(249, 187, 18, 1)',
    ],
  };
};
/**
 * @class bar 图系 单bar 或 叠bar
 */
@Component({
  mixins: [resize],
  mounted() {
    if (this.data && this.data.length) this.initChart();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
})
export default class Share extends Vue {
  @Prop({ type: String, default: 'topShareChart' }) id;
  @Prop({ type: String, default: '361px' }) height;
  @Prop({ type: Object, default: () => {} }) data;
  get isNoData() {
    if (!this.data.xAxis || (this.data.xAxis && !this.data.xAxis.length)) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  initChart() {
    this.chart = echarts.init(document.getElementById(this.id));
    this.chart.setOption(barOption(this.data));
  }
  value = 'NUMBER';
  handleChange(value) {
    this.value = value;
    this.$emit('change', value);
  }
}
</script>

<style lang="less" module>
.container {
  height: 100%;
  position: relative;

  .common {
    position: absolute;
    height: 54px;
    line-height: 54px;
    z-index: 1;
    display: inline-block;
  }
  .title {
    font-size: 18px;
    text-align: left;
    left: 0;
    top: 0;
    color: var(--font);
  }
  .selectBox {
    width: 100px;
    right: 0;
    top: 0;
  }
}
.chartBox {
  width: 100%;
}
</style>
