<template>
  <a-carousel ref="carouselRef" :after-change="onChange" :dots="false">
    <div>
      <slot name="first"></slot>
    </div>
    <div>
      <slot name="second"></slot>
    </div>
  </a-carousel>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component()
export default class Carousel extends Vue {
  @Prop({ type: Function, default: function() {} }) onChange;

  handleGoTo(index) {
    this.$refs.carouselRef.goTo(index);
  }
}
</script>
<style lang="less" module>
:global {
  .ant-carousel .slick-slide {
    text-align: center;
    // overflow: hidden;
  }
  .ant-carousel .slick-slide h3 {
    color: #fff;
  }
}
</style>
