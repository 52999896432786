<template>
  <div :class="$style.container">
    <div :class="[$style.title, $style.common]">
      <a-tooltip :title="$t('iotScreenManage.visitingDesc')">
        {{ $t('iotScreenManage.visitingDetails') }}&nbsp;&nbsp;<a-icon
          type="exclamation-circle"
        />
      </a-tooltip>
    </div>
    <div :class="[$style.selectBox, $style.common]">
      <a-select
        default-value="NUMBER"
        style="width: 100px"
        @change="handleChange"
      >
        <a-select-option value="NUMBER">
          {{ $t('iotScreenManage.visits') }}
        </a-select-option>
        <a-select-option value="COUNT">
          {{ $t('iotScreenManage.clicks') }}
        </a-select-option>
      </a-select>
    </div>
    <no-data-chart
      font="120px"
      v-if="isNoData"
      isIcon
      :style="{ height: '320px', width: '100%' }"
    />
    <div v-else :id="id" :class="$style.chartBox" :style="{ height }" />
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as echarts from 'echarts';
import resize from '@/utils/resize.js';
import { globalVueI18n } from '@triascloud/i18n';

const barWidthOption = len => {
  const Key = {
    0: {
      barWidth: 30,
    },
    1: {
      barWidth: 30,
    },
    2: {
      barWidth: 20,
    },
    3: {
      barWidth: 15,
    },
  };
  return Key[Math.floor(len / 10)];
};

const barOption = data => {
  const bodyCss = getComputedStyle(document.body);
  const color = '#00A4A4';

  /** @name 柱状图的宽度大小 */
  let barWidth = barWidthOption(data.times.length).barWidth;

  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
      },
      formatter: function(params) {
        let result = `<div>${params[0].name}</div>`;
        params.forEach(item => {
          result += `<div style="text-align: left;">
            <span style="background-color: ${item.color}; display: inline-block; width: 10px; height: 10px;border-radius: 100%;"></span>
            &nbsp;&nbsp${item.seriesName}:&nbsp&nbsp${item.data}
            </div>`;
        });

        return result;
      },
    },
    legend: {
      data: [
        globalVueI18n.translate('screen.legend.generalContracting'),
        globalVueI18n.translate('screen.legend.tenant'),
        globalVueI18n.translate('screen.legend.area'),
      ],
      right: '120',
      top: '15',
      textStyle: {
        color: '#9A9A9A',
        fontSize: '14',
      },
    },
    grid: {
      left: '0',
      right: '0',
      top: 77,
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: data.times,
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        color: '#9A9A9A',
      },
      axisLine: {
        lineStyle: {
          color: bodyCss.getPropertyValue('--border'),
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false,
      },
      axisLabel: {
        color: '#9A9A9A',
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: bodyCss.getPropertyValue('--border'),
        },
      },
    },
    series: [
      {
        name: globalVueI18n.translate('screen.legend.generalContracting'),
        type: 'bar',
        stack: '总量',
        barWidth,
        label: {
          show: false,
        },
        lineStyle: { color },
        data: data.listEpc,
      },
      {
        name: globalVueI18n.translate('screen.legend.tenant'),
        type: 'bar',
        stack: '总量',
        barWidth,
        label: {
          show: false,
        },
        lineStyle: { color },
        data: data.listLeaseholder,
      },
      {
        name: globalVueI18n.translate('screen.legend.area'),
        type: 'bar',
        stack: '总量',
        barWidth,
        label: {
          show: false,
        },
        lineStyle: { color },
        data: data.listArea,
      },
    ],
    color: [
      'rgba(6, 173, 135, 1)',
      'rgba(58, 85, 234, 1)',
      'rgba(249, 187, 18, 1)',
    ],
  };
};
/**
 * @class bar 图系 单bar 或 叠bar
 */
@Component({
  mixins: [resize],
  mounted() {
    if (this.data && this.data.length) this.initChart();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
})
export default class VisitSurvey extends Vue {
  @Prop({ type: String, default: 'shareChart' }) id;
  @Prop({ type: String, default: '361px' }) height;
  @Prop({ type: Object, default: () => {} }) data;
  get isNoData() {
    if (!this.data.times || (this.data.times && !this.data.times.length)) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  initChart() {
    this.chart = echarts.init(document.getElementById(this.id));
    this.chart.setOption(barOption(this.data));
  }
  handleChange(value) {
    this.$emit('change', value);
  }
}
</script>

<style lang="less" module>
.container {
  height: 100%;
  position: relative;

  .common {
    position: absolute;
    height: 54px;
    line-height: 54px;
    display: inline-block;
    z-index: 1;
  }
  .title {
    font-size: 18px;
    text-align: left;
    left: 0;
    top: 0;
    color: var(--font);
  }
  .selectBox {
    width: 100px;
    right: 0;
    top: 0;
  }
}
.chartBox {
  width: 100%;
}
</style>
