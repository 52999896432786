<template>
  <layout-content :class="$style.screenLayout">
    <template v-slot:header-left>
      <span :class="$style.title">{{
        $t('matterwebset.screenmanage.title')
      }}</span>
    </template>
    <div :class="$style.screenManageContent" slot="content" ref="content">
      <!-- 入口 -->
      <div :class="$style.symmetryContain">
        <div :class="$style.entryBox">
          <div :class="$style.entryBlock">
            <img
              src="../../../assets/images/iot-screen-manage/contracting.png"
            />
            <div :class="$style.census">
              <h3>
                {{ $t('matterwebset.screenmanage.totalNumber') }}
                <!-- 项目总数： -->
              </h3>
              <span :class="$style.sum">{{ userStatics.contractorCount }}</span>
            </div>
          </div>
          <div :class="$style.entryBlock">
            <div :class="$style.title">
              <h4>
                {{ $t('matterwebset.screenmanage.contractscreen') }}
                <!-- 项目角色大屏 -->
              </h4>
              <a-button
                :class="$style.innerButton"
                @click="openModal(SCREEN_PROJECT_ROLE)"
                >{{ $t('matterwebset.screenmanage.shareText') }}
                <a-icon type="double-right"
              /></a-button>
            </div>
          </div>
        </div>
        <div :class="[$style.entryBox, $style.supervision]">
          <div :class="$style.entryBlock">
            <img src="~@/assets/images/iot-screen-manage/supervision.png" />
            <div :class="$style.census">
              <h3>
                {{ $t('matterwebset.screenmanage.numberOfProjecctAreas') }}
              </h3>
              <span :class="$style.sum">{{
                userStatics.safetySupervisionBureauCount
              }}</span>
              <p>
                ( {{ $t('matterwebset.screenmanage.province') }}:{{
                  userStatics.provinceCount
                }}
                <span :class="$style.cityCount">
                  {{ $t('matterwebset.screenmanage.city') }}:{{
                    userStatics.cityCount
                  }}</span
                >

                {{ $t('matterwebset.screenmanage.area') }}:{{
                  userStatics.countyCount
                }}
                )
              </p>
            </div>
          </div>
          <div :class="$style.entryBlock">
            <div :class="$style.title">
              <h4>
                {{ $t('matterwebset.screenmanage.supervisionscreen') }}
              </h4>
              <a-button
                :class="$style.innerButton"
                @click="openModal(SCREEN_AREA)"
                >{{ $t('matterwebset.screenmanage.shareText') }}
                <a-icon type="double-right"
              /></a-button>
            </div>
          </div>
        </div>
      </div>
      <share-count ref="shareCountRef" />
    </div>
  </layout-content>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import { createFormModal, createModal } from '@triascloud/x-components';
import ShareForm from './share-form';
import ShareBox from './share-box';
import { getUserNumber } from '@/services/things/screen-manage';
import { SCREEN_PROJECT_ROLE, SCREEN_AREA } from './final';
import ShareCount from './components/share-count';

@Component({
  components: {
    LayoutContent,
    ShareCount,
  },
  created() {
    getUserNumber().then(res => {
      this.userStatics = res;
    });
  },
})
export default class IotScreenManage extends Vue {
  SCREEN_PROJECT_ROLE = SCREEN_PROJECT_ROLE;
  SCREEN_AREA = SCREEN_AREA;
  userStatics = {
    cityCount: 0,
    contractorCount: 0,
    countyCount: 0,
    provinceCount: 0,
    safetySupervisionBureauCount: 0,
  };
  visible = false;
  modalType = 0;
  async openModal(val) {
    const shareInfo = await createFormModal(() => <ShareForm type={val} />, {
      width: '670px',
      title:
        val === SCREEN_AREA
          ? this.$t('iotScreenManage.areaScreen')
          : this.$t('iotScreenManage.projectRoleScreen'),
      getContainer: () => this.$refs.content,
    });
    await createModal(() => <ShareBox shareInfo={shareInfo} />, {
      width: '420px',
      title: this.$t('iotScreenManage.share'),
      getContainer: () => this.$refs.content,
    });
    this.$store.dispatch('shareScreen/getShareList');
    this.$refs.shareCountRef && this.$refs.shareCountRef.getQuery();
  }
}
</script>
<style lang="less" module>
.screenLayout {
  .title {
    font-size: 16px;
  }
  .screenManageContent {
    padding: 30px;
  }
  .symmetryContain {
    display: flex;
    justify-content: space-between;
    min-width: 1000px;
    .symmetryBox,
    .entryBox {
      display: flex;
      flex: 1;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
    .entryBox {
      padding: 46px;
      background-color: #00b893;
      border-radius: 20px;
      color: #fff;
    }
    .supervision {
      background-color: #4360ef;
    }
  }
  .entryBlock {
    flex: 1;
    border-right: 1px solid #ffffff9a;
    display: flex;
    &:last-child {
      border-right: none;
    }
    img {
      width: 80px;
      height: 80px;
      margin-right: 44px;
    }
    .census {
      flex: 1;
      h3 {
        font-size: 18px;
        font-weight: normal;
        margin-bottom: 5px;
        color: #fff;
      }
      .sum {
        font-size: 42px;
        font-weight: bold;
      }
      p {
        font-size: 14px;
        white-space: nowrap;
      }
    }
    .title {
      width: 100%;
      text-align: center;
      font-size: 30px;
      white-space: nowrap;
      h4 {
        color: #fff;
      }
    }
    .innerButton {
      border-color: #fff;
      font-size: 18px;
      color: #fff;
      background-color: #ffffff21;
      padding-right: 40px;
      padding-left: 40px;
      margin-top: 27px;
      border-radius: 20px;
      &:hover {
        background-color: #ffffff6d;
      }
    }
  }
  .boxTitle {
    position: relative;
    margin: 40px 0 30px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 700;
    color: var(--font);
    &::before {
      display: inline-block;
      margin-bottom: -2px;
      margin-right: 10px;
      content: '';
      width: 16px;
      height: 16px;
      box-sizing: border-box;
      border: 4px solid var(--font-active-light);
      box-shadow: 0px 1px 4px 1px var(--font-active-light);
      border-radius: 50%;
    }
  }
  .buttonBox {
    margin: 30px 0 10px;
    overflow: hidden;
  }
  .buttonList {
    float: right;
    .inButton {
      margin-left: 10px;
      height: 32px;
      line-height: 32px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .card {
    border-radius: 12px;
    box-shadow: 1px 1px 10px rgba(137, 131, 131, 0.08);
    .cardContain {
      width: 100%;
    }
    .cardName {
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      font-size: 18px;
      font-weight: 600;
      color: var(--chartTitle);
      border-bottom: 1px solid var(--border);
    }
    .chartBox {
      height: 322px;
    }
  }
  .cityCount {
    margin: 0 0.5em;
  }
  :global(.ant-modal-body) {
    overflow-y: unset;
  }
}
</style>
