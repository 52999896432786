<template>
  <div class="container">
    <div class="buttonList" v-if="effective">
      <a-button
        v-for="(item, key) in buttonList"
        :key="key"
        :type="range === item.value ? 'primary' : ''"
        @click="rangeChange(item.value)"
        class="inButton"
      >
        {{ item.title }}
      </a-button>
    </div>
    <div :id="chartId" class="chartBox" :style="{ height }" />
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { getAccessTrend } from '@/services/things/screen-count';
import resize from '@/utils/resize.js';
import { globalVueI18n } from '@triascloud/i18n';

const option = (data, effective) => {
  const bodyCss = getComputedStyle(document.body);
  // const color = '#00A4A4';
  let gridTop = '2%';
  if (effective) {
    gridTop = '12%';
  }
  return {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: [
        globalVueI18n.translate('iotScreenManage.clicks'),
        globalVueI18n.translate('iotScreenManage.visits'),
      ],
      bottom: '0',
      textStyle: {
        color: '#9A9A9A',
      },
    },
    grid: {
      left: '2%',
      right: '4%',
      bottom: '8%',
      top: gridTop,
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: data.times,
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        color: '#9A9A9A',
      },
      axisLine: {
        lineStyle: {
          color: bodyCss.getPropertyValue('--border'),
        },
      },
    },
    yAxis: {
      type: 'value',

      axisLine: {
        show: false,
      },
      axisLabel: {
        color: '#9A9A9A',
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: bodyCss.getPropertyValue('--border'),
        },
      },
    },
    series: [
      {
        name: globalVueI18n.translate('iotScreenManage.clicks'),
        type: 'line',
        smooth: true,
        data: data.listCount,
      },
      {
        name: globalVueI18n.translate('iotScreenManage.visits'),
        type: 'line',
        smooth: true,
        data: data.listPerson,
      },
    ],
    color: ['#26B97B', '#3A55EA'],
  };
};
/**
 * @class bar 图系 单bar 或 叠bar
 */
@Component({
  mixins: [resize],
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
})
export default class Visit extends Vue {
  @Prop({ type: String, default: 'visitTrendChart' }) chartId;
  @Prop({ type: String, default: '' }) shareId;
  @Prop({ type: String, default: '320px' }) height;
  @Prop({ type: Boolean, default: false }) effective;
  chart = null;
  initChart() {
    this.chart = echarts.init(document.getElementById(this.chartId));
    this.chart.setOption(option(this.chartData, this.effective));
  }

  @Watch('shareId', { immediate: true })
  async onShareIdChange(id) {
    try {
      await this.getAccessTrendApi({
        screenId: id,
        screenCountDate: 'BEFORE_SEVEN_DATE',
        screenCountType: 'ACCESS',
      });
    } catch (error) {
      this.$message.error(error.message);
    }
  }
  formatData(result) {
    const times = [],
      listCount = [],
      listPerson = [];
    Object.keys(result).forEach(key => {
      times.push(key);
      Object.keys(result[key]).forEach(v => {
        listCount.push(v);
        listPerson.push(result[key][v]);
      });
    });
    return {
      times,
      listCount,
      listPerson,
    };
  }

  chartData = {};
  async getAccessTrendApi(option) {
    const result = await getAccessTrend(option);
    if (Object.keys(result).length > 0) {
      this.chartData = this.formatData(result);
    } else {
      this.chartData = {
        times: [new Date().toLocaleDateString()],
        listCount: [0],
        listPerson: [0],
      };
    }

    this.$nextTick(this.initChart);
  }
  range = 'BEFORE_SEVEN_DATE';
  async rangeChange(value) {
    this.range = value;
    try {
      await this.getAccessTrendApi({
        screenId: this.shareId,
        screenCountDate: value,
        screenCountType: 'ACCESS',
      });
    } catch (error) {
      this.$message.error(error.message);
    }
  }
  get buttonList() {
    return [
      {
        title: this.$t('matterwebset.screenmanage.last7'),
        value: 'BEFORE_SEVEN_DATE',
      },
      {
        title: this.$t('matterwebset.screenmanage.last30'),
        value: 'BEFORE_THIRTY_DATE',
      },
      {
        title: this.$t('matterwebset.screenmanage.currentmonth'),
        value: 'CURRENT_MONTH',
      },
    ];
  }
}
</script>

<style lang="less" scoped>
.buttonList {
  position: relative;
  z-index: 1;
  float: right;

  .inButton {
    margin-left: 10px;
    height: 32px;
    line-height: 32px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.container {
  height: 100%;
  position: relative;
}
.chartBox {
  width: 650px;
}
</style>
