import request from '../request';

const serviceName = '/things/screenCount';

/**
 * @name 分享增加
 */
export function addByShare(data) {
  return request(`${serviceName}/addByShare`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: data,
  });
}

/**
 * @name 获取分享-统计次数
 */
export function getScreenCount(data) {
  return request(`${serviceName}/getScreenCount`, {
    method: 'GET',
    body: data,
  });
}

/**
 * @name 分享次数TOP10
 */
export const getTop10ForShare = data => {
  return request(`${serviceName}/ShareTop`, {
    method: 'GET',
    body: data,
  });
};

/**
 * @name 访问趋势
 */
export const getAccessTrend = data => {
  return request(`${serviceName}/accessTrend`, {
    method: 'GET',
    body: data,
  });
};

/**
 * @name 分享次数
 */
export const getSharingStatistics = data => {
  return request(`${serviceName}/sharingStatistics`, {
    method: 'GET',
    body: data,
  });
};
