<template>
  <a-table
    :columns="columns"
    :row-key="record => record.id"
    :data-source="list"
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
  >
    <template slot="$HeaderCount">
      <a-tooltip :title="$t('iotScreenManage.visitingDesc')">
        {{ $t('iotScreenManage.clicksVisits') }}&nbsp;&nbsp;<a-icon
          type="exclamation-circle"
        />
      </a-tooltip>
    </template>
    <template slot="action" slot-scope="record">
      <span :class="$style.buttonGroup">
        <a
          :class="$style.button"
          @click="handleShareHistory(record)"
          v-if="isValid(record.item)"
          >{{ $t('iotScreenManage.share') }}</a
        >
        <a :class="$style.button" @click="handleVisitTrend(record.item)">{{
          $t('iotScreenManage.trends')
        }}</a>
        <a
          :class="$style.button"
          @click="handleShareOver(record.id)"
          v-if="isValid(record.item)"
          >{{ $t('iotScreenManage.end') }}</a
        >
      </span>
    </template>
  </a-table>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { createModal, createFormModal } from '@triascloud/x-components';
import Visit from './chart/visit';
import {
  linkExpired,
  encryptionId,
  isValid,
} from '@/services/things/screen-manage';
import ShareBox from '../share-box';
import { shareScreenModule, crossStorageModule } from '@/enum/store';

@Component()
export default class shareDetailTable extends Vue {
  @shareScreenModule.State('pagination') pagination;
  @shareScreenModule.Mutation('SET_PAGINATION') setPagination;
  @shareScreenModule.Action('getShareList') getShareList;
  @crossStorageModule.State skin;

  created() {
    this.isValid = isValid;
  }

  columns = [
    {
      title: this.$t('iotScreenManage.sharingDimension'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: this.$t('iotScreenManage.sharingType'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: this.$t('iotScreenManage.time'),
      dataIndex: 'time',
      key: 'time',
    },
    {
      dataIndex: 'count',
      key: 'count',
      slots: {
        title: '$HeaderCount',
      },
    },
    {
      title: this.$t('iotScreenManage.expireDate'),
      dataIndex: 'expired',
      key: 'expired',
    },
    {
      title: this.$t('iotScreenManage.status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: this.$t('iotScreenManage.operator'),
      dataIndex: 'author',
      key: 'author',
    },
    {
      title: this.$t('iotScreenManage.operation'),
      key: 'action',
      scopedSlots: { customRender: 'action' },
    },
  ];

  get list() {
    return this.$store.state.shareScreen.shareList;
  }

  get pagination() {
    return {
      ...this.$store.state.shareScreen.pagination,
      showQuickJumper: true,
      showSizeChanger: true,
      showTotal: total =>
        `${this.$t('electricBoxMock.message.total')} ${total} ${this.$t(
          'electricBoxMock.message.record',
        )}`,
      onShowSizeChange: this.sizeChange,
    };
  }
  loading = false;
  async handleTableChange(pagination) {
    this.setPagination({
      ...this.pagination,
      current: pagination.current,
    });
    await this.getShareList();
  }
  async sizeChange(page, size) {
    this.setPagination({
      ...this.pagination,
      pageSize: size,
      current: 1,
    });
    await this.getShareList();
  }

  async handleVisitTrend(item) {
    const effective = item.finallyEffectiveDate === 'FOREVER';
    const skin = this.skin;
    await createModal(
      () => <Visit shareId={item.id} effective={effective} skin={skin} />,
      {
        width: '690px',
        title: this.$t('iotScreenManage.trends'),
        className: 'visit-chart-box',
      },
    );
  }

  async handleShareOver(id) {
    try {
      await createFormModal(
        () => <span>{this.$t('iotScreenManage.sharingDesc')}</span>,
        {
          width: '420px',
          title: this.$t('iotScreenManage.end'),
          onOk: () => this.shareOver(id),
        },
      );
      this.$message.success('操作成功');
    } catch (error) {
      if (error.message !== 'user cancel') this.$message.error(error.message);
    }
  }
  async shareOver(id) {
    try {
      await linkExpired({
        id,
      });
      this.setPagination({
        ...this.pagination,
        current: 1,
      });
      await this.getShareList();
    } catch (error) {
      this.$message.error(error.message);
    }
  }

  async handleShareHistory(info) {
    const resultId = await encryptionId({ id: info.item.id });
    info.item.shareUrl = `${window.location.origin}/iot/generalScreen/proof/${resultId}`;
    await createModal(() => <ShareBox shareInfo={info.item} />, {
      width: '420px',
      title: this.$t('iotScreenManage.share'),
    });
  }
}
</script>
<style lang="less" module>
.buttonGroup {
  .button {
    padding-right: 40px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: 1px;
      height: 26px;
      background-color: var(--border);
    }
  }
  .button:last-of-type {
    &::after {
      width: 0;
    }
  }
}
:global(.visit-chart-box .ant-modal-body) {
  margin-bottom: 0;
}
</style>
