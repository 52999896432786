<template>
  <div :class="$style.container" ref="container">
    <a-form-model
      :model="form"
      ref="form"
      hideRequiredMark
      :class="$style.form"
      validateOnRuleChange
      :rules="rules"
    >
      <a-form-model-item
        :class="$style.formItem"
        labelAlign="left"
        v-if="type === SCREEN_AREA"
        prop="province"
      >
        <template slot="label">{{
          $t('matterwebset.screenmanage.supervisiontext')
        }}</template>
        <a-select
          :class="$style.inSelect"
          v-model="form.address.province"
          @change="resetCity"
        >
          <a-select-option
            v-for="item in provinceList"
            :key="item"
            :value="item"
            >{{ item }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        labelAlign="left"
        v-if="type === SCREEN_AREA"
        label=""
        :class="$style.formItem"
        prop="city"
      >
        <a-select
          :class="$style.inSelect"
          v-model="form.address.city"
          @change="resetArea"
          :getPopupContainer="() => $refs.container"
        >
          <a-select-option v-for="item in cityList" :key="item" :value="item">{{
            item
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        :class="$style.formItem"
        v-if="type === SCREEN_AREA"
        label=""
        prop="area"
      >
        <a-select
          :class="$style.inSelect"
          v-model="form.address.area"
          :getPopupContainer="() => $refs.container"
        >
          <a-select-option v-for="item in areaList" :key="item" :value="item">{{
            item
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        :labelCol="{ span: 4 }"
        labelAlign="left"
        :wrapperCol="{ span: 20 }"
        v-if="type === SCREEN_PROJECT_ROLE"
      >
        <template slot="label"> {{ $t('iotScreenManage.role') }}： </template>
        <a-radio-group v-model="form.role">
          <a-radio :value="ROLE_GENERAL">
            {{ $t('iotScreenManage.contractor') }}
          </a-radio>
          <a-radio :value="ROLE_LEASEHOLDER">
            {{ $t('iotScreenManage.tenant') }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        v-if="type === SCREEN_PROJECT_ROLE"
        :labelCol="{ span: 4 }"
        labelAlign="left"
        :wrapperCol="{ span: 20 }"
        :prop="form.role === ROLE_GENERAL ? 'epcId' : 'leaseholderId'"
      >
        <template slot="label">
          {{ $t('iotScreenManage.pleaseSelect') }}：
        </template>

        <a-select
          v-if="form.role === ROLE_GENERAL"
          v-model="form.epcId"
          showSearch
          :filterOption="filterOption"
          :options="epcList.slice(0, loadingCount)"
          @focus="focus"
          @blur="blur"
        />
        <a-select
          v-else
          v-model="form.leaseholderId"
          showSearch
          :filterOption="filterOption"
          :options="leaseholderList"
        />
      </a-form-model-item>
      <a-form-model-item
        :labelCol="{ span: 4 }"
        labelAlign="left"
        :wrapperCol="{ span: 20 }"
        prop="password"
      >
        <template slot="label">{{
          $t('matterwebset.screenmanage.sharingway')
        }}</template>
        <a-radio-group v-model="setPassword">
          <a-radio :value="0">{{
            $t('matterwebset.screenmanage.nopassword')
          }}</a-radio>
          <a-radio :value="1">{{
            $t('matterwebset.screenmanage.setpassword')
          }}</a-radio>
        </a-radio-group>
        <a-input
          v-if="setPassword"
          @change="input"
          v-model="form.password"
          :placeholder="$t('iotScreenManage.pleaseSetFourPwd')"
          :class="$style.password"
        />
      </a-form-model-item>
      <a-form-model-item
        :labelCol="{ span: 4 }"
        labelAlign="left"
        :wrapperCol="{ span: 20 }"
        prop="effectiveDate"
      >
        <template slot="label">{{
          $t('matterwebset.screenmanage.linkvalidity')
        }}</template>
        <a-radio-group v-model="form.effectiveDate">
          <a-radio value="FOREVER">{{
            $t('matterwebset.screenmanage.forever')
          }}</a-radio>
          <a-radio value="THIRTY">{{
            $t('matterwebset.screenmanage.thirty')
          }}</a-radio>
          <a-radio value="SEVEN">{{
            $t('matterwebset.screenmanage.seven')
          }}</a-radio>
          <a-radio value="ONE">{{
            $t('matterwebset.screenmanage.one')
          }}</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  generate,
  getContractorUnitList,
  getContractorList,
  getNotRepeatingAddress,
} from '@/services/things/screen-manage';
import { TreeSelect as XTreeSelect } from '@triascloud/x-components';
import { SCREEN_PROJECT_ROLE, SCREEN_AREA } from './final';

const ruleFn = self => {
  const opt = {
    effectiveDate: [{ required: true }],
  };
  if (self.type === SCREEN_PROJECT_ROLE) {
    return {
      ...opt,
      epcId: [
        {
          required: true,
          message: self.$t('iotScreenManage.pleaseSelectContractor'),
          trigger: 'blur',
        },
      ],
      leaseholderId: [
        {
          required: true,
          message: self.$t('iotScreenManage.pleaseSelectTenant'),
          trigger: 'blur',
        },
      ],
    };
  } else {
    return {
      ...opt,
    };
  }
};
const initForm = {
  password: '',
  effectiveDate: 'SEVEN',
  address: {
    province: undefined,
    city: undefined,
    area: undefined,
  },
  epcId: '',
  leaseholderId: '', // 租赁商ID
  role: 'GENERAL',
};
@Component({
  components: {
    XTreeSelect,
  },
  created() {
    this.form = JSON.parse(JSON.stringify(initForm));
    if (this.type === SCREEN_AREA) {
      this.getProviceList();
    } else {
      this.getContractorUnitList();
      this.getContractorList();
    }
  },
})
export default class ShareForm extends Vue {
  @Prop({ default: SCREEN_PROJECT_ROLE, type: String }) type;
  form = {};
  provinceList = [];
  projectList = [];
  setPassword = 0;
  cityList = [];
  areaList = [];
  epcList = [];
  loadingCount = 200;
  delayHand = null;
  ROLE_LEASEHOLDER = 'LEASEHOLDER'; // 角色：租赁商
  ROLE_GENERAL = 'GENERAL'; // 角色：总包
  SCREEN_PROJECT_ROLE = SCREEN_PROJECT_ROLE;
  SCREEN_AREA = SCREEN_AREA;

  focus() {
    this.delaySelectLoad();
  }
  delaySelectLoad() {
    if (this.epcList.length <= this.loadingCount) return;
    this.loadingCount += 100;
    this.delayHand = setTimeout(this.delaySelectLoad, 666);
  }
  blur() {
    this.delayHand && clearTimeout(this.delayHand);
    this.loadingCount = 200;
  }
  get rules() {
    return {
      ...ruleFn(this),
      password: [
        {
          required: this.setPassword,
          message: this.$t('iotScreenManage.pleaseSetPwd'),
          trigger: 'blur',
        },
        {
          len: 4,
          message: this.$t('iotScreenManage.pleaseSetFourPwd'),
          trigger: 'change',
        },
      ],
    };
  }
  getContractorUnitList() {
    getContractorUnitList().then(res => {
      this.epcList = res
        .filter(e => e.name && e.contractorUnitSerial)
        .map(({ name, contractorUnitSerial }) => ({
          key: contractorUnitSerial,
          title: name,
        }));
    });
  }

  leaseholderList = []; // 租赁商列表
  getContractorList() {
    getContractorList().then(res => {
      this.leaseholderList = res
        .filter(e => e.contractor && e.pkId)
        .map(({ contractor, pkId }) => ({
          key: pkId,
          title: contractor,
        }));
    });
  }
  getContractorName(id) {
    return (
      this.leaseholderList &&
      this.leaseholderList.find(leaseholder => leaseholder.key === id).title
    );
  }

  async getValue() {
    await this.$refs.form.validate();
    const params = {
      ...this.form,
      password: this.setPassword ? this.form.password : '',
    };
    if (params.role) delete params.role;
    if (this.type === SCREEN_PROJECT_ROLE) {
      if (this.form.role === this.ROLE_GENERAL) {
        params.type = 'EPC';
        if (!this.form.epcId) {
          return Promise.reject(false);
        }
        params.epcId = this.form.epcId;
      } else {
        params.type = 'CONTRACTORINFO';
        params.contractorId = this.form.leaseholderId;
        params.contractorName = this.getContractorName(this.form.leaseholderId);
      }
    } else {
      params.type = 'SECURITY_BUREAU';
    }
    // 没有省市区的数据，不能分享
    if (
      params.type === 'SECURITY_BUREAU' &&
      this.loading &&
      this.provinceList.length === 0
    ) {
      this.$message.error('没有项目可供分享，请先创建项目！');
      return Promise.reject(false);
    }
    const shareInfo = await generate(params);
    shareInfo.shareUrl = `${window.location.origin}/iot/generalScreen/proof/${shareInfo.id}`;
    return shareInfo;
  }
  /**
   * @name 密码锁定只能用字母+数字4位
   */
  input({ target: { value } }) {
    value = value.replace(/[^a-zA-Z0-9]/g, '');
    value = value.substring(0, 4);
    this.form.password = value;
    this.$refs.form.validateField('password');
  }
  loading = false;
  async getProviceList() {
    const result = await getNotRepeatingAddress();
    this.provinceList = result.filter(e => e);
    this.loading = true;
  }
  resetCity(val) {
    this.form = {
      ...this.form,
      address: { province: val, city: undefined, area: undefined },
    };
    val &&
      getNotRepeatingAddress({ ...this.form.address }).then(res => {
        this.cityList = res.filter(e => e);
      });
  }
  resetArea(val) {
    this.form = {
      ...this.form,
      address: { ...this.form.address, city: val, area: undefined },
    };
    val &&
      getNotRepeatingAddress({ ...this.form.address }).then(res => {
        this.areaList = res.filter(e => e);
      });
  }
  filterOption(val, option) {
    const title = option.data.props.title;
    return title.indexOf(val) !== -1;
  }
}
</script>

<style lang="less" module>
.formItem {
  display: inline-block;
  width: 26.59%;
  :global(.ant-col.ant-form-item-control-wrapper) {
    float: right;
    width: 90%;
  }
  &:first-child {
    width: 40%;
    :global(.ant-col.ant-form-item-control-wrapper) {
      width: 60%;
    }
  }
}
.container {
  :global(input) {
    background-color: var(--dropdown-bg) !important;
  }
}
.buttonList {
  text-align: right;
  margin-top: 130px;
}
.inSelect {
  width: 160px;
  // margin-right: 10px;
  // &:last-child {
  //   margin-right: 0;
  // }
}
.password {
  width: 174px;
  margin-left: 20px;
}
</style>
